var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('nav',{staticClass:"p-3",attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{name: 'projects-index'}}},[_vm._v("Projects")])],1),(_vm.project)?_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
								name: 'projects-editor-uuid-index',
								params: {
									uuid: _vm.project.Uuid
								}
							}},domProps:{"textContent":_vm._s(`${_vm.project.Code} - ${_vm.project.Name}`)}})],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item"},[(_vm.configuration)?_c('router-link',{attrs:{"to":{
						name: 'projects-editor-uuid-cuuid-localizations-index',
						params: {
							uuid: _vm.$route.params.uuid,
							cuuid: _vm.$route.params.cuuid
						}
					}}},[_vm._v(_vm._s(_vm.configuration.Name)+" "+_vm._s(`v. ${_vm.configuration.Version}`)+" ")]):_vm._e()],1),(_vm.localization_project)?_c('li',{staticClass:"breadcrumb-item active"},[_c('router-link',{attrs:{"to":{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-index',
						params: {
							uuid: _vm.$route.params.uuid,
							cuuid: _vm.$route.params.cuuid,
							project_id: _vm.$route.params.project_id
						}
					}}},[_vm._v(_vm._s(_vm.localization_project.Name)+" ")])],1):_vm._e(),_c('li',{staticClass:"breadcrumb-item active"},[_vm._v(" Game guide ")])])]),(_vm.loading)?_c('div',{staticClass:"spinner-border ml-3",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('div',{staticClass:"p-5 bg-light"},[_c('div',[_vm._v("Available translations")]),_c('div',{staticClass:"p-3 d-flex justify-content-around"},_vm._l((_vm.languages),function(language){return (_vm.validLanguages.length === 0 || _vm.validLanguages.includes(language.Code))?_c('div',{staticClass:"text-center d-flex flex-column flex-grow-1"},[(_vm.translations.length != 0)?_c('button',{staticClass:"btn btn-success mr-2",attrs:{"disabled":language.DefaultForTranslation == 1},on:{"click":function($event){return _vm.generateTranslations(language)}}},[_vm._v("GENERATE TRANSLATIONS ("+_vm._s(language.Code.toUpperCase())+")")]):_vm._e(),(_vm.translations.length != 0)?_c('div',[_vm._v(" ")]):_vm._e(),_c('router-link',{staticClass:"flex-grow-1 btn mr-2",class:{'btn-primary': _vm.translations.includes(language.Code), 'btn-info': !_vm.translations.includes(language.Code)},attrs:{"to":{
						name: 'projects-editor-uuid-cuuid-localizations-project_id-guide-language',
						params: {
							uuid: _vm.$route.params.uuid,
							cuuid: _vm.$route.params.cuuid,
							project_id: _vm.$route.params.project_id,
							language: language.Code
							}
					}}},[_c('div',[_vm._v(_vm._s(language.Name))])]),_c('div',{staticClass:"text-small"},[_vm._v(_vm._s(!_vm.translations.includes(language.Code) ? 'Missing' : 'Done'))])],1):_vm._e()}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }